<template>
  <div class="mb-5">
    <v-checkbox
        v-if="isCheckbox"
        v-model="innerValue"
        :label="label"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errorMessages"
        :hint="hint"
    />
    <v-radio-group
        v-else
        v-model="innerValue"
        :label="label + ':'"
        :disabled="disabled"
        :readonly="readonly"
        :error-messages="errorMessages"
        :hint="hint"
        row
    >
      <v-radio
          label="Yes"
          :value="true"
          @click="clickRadiobutton(true)"
      />
      <v-radio
          label="No"
          :value="false"
          @click="clickRadiobutton(false)"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'BooleanField',
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    fieldProperties: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },
  emits: ['input'],
  computed: {
    isCheckbox() {
      return false;
    },
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        let value = null;
        if (this.isCheckbox) {
          value = val ? true : null;
        } else {
          value = val;
        }
        this.$emit('input', value);
      },
    },
  },
  methods: {
    clickRadiobutton(checkboxValue) {
      if (checkboxValue === this.innerValue) {
        this.innerValue = null;
      }
    },
  },
};
</script>
