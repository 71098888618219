import _ from 'lodash';

const objectUtils = {
  setValueByObjectPath(obj, path, value, cloneObject = true) {
    if (!path || typeof path !== 'string') {
      throw new Error('Path must be a non-empty string');
    }
    const keys = path.split('.');
    const lastKey = keys.pop();

    let object = cloneObject ? _.cloneDeep(obj) : obj;

    if (!object) {
      object = {};
    }

    let current = object;

    keys.forEach(key => {
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    });

    current[lastKey] = value;
    return object;
  },

  getByObjectPath(obj, path) {
    if (!path) {
      return null;
    }
    return path.split('.').reduce((current, key) => (current && current[key] ? current[key] : null), obj);
  },

  removeNullValues(data) {
    if (Array.isArray(data)) {
      return data
        .filter(item => item !== null)
        .map(item => this.removeNullValues(item));
    } if (typeof data === 'object' && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        if (value !== null) {
          acc[key] = this.removeNullValues(value);
        }
        return acc;
      }, {});
    }
    return data;
  },
};
export default objectUtils;
