<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="{
        itemsPerPageOptions: [10,25,50]
      }"
        fixed-header
        class="neris-incidents-table header-nowrap"
        no-data-text="No data available"
        no-results-text="No data available"
    >
      <template v-slot:item.incidentNumber="{ item }">
        <router-link
          :to="{
            name: routes.NERIS_INCIDENT,
            params: { incidentNumber: item.incidentNumber }
          }"
        >
          {{ item.incidentNumber }}
        </router-link>
      </template>
      <template v-slot:item.incidentDateTime="{ item }">
        {{ item.incidentDateTime | datetime }}
      </template>
      <template v-slot:item.submittedAt="{ item }">
        {{ item.submittedAt | datetimeToClientTimezone }}
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | datetimeToClientTimezone }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          v-if="item"
          small
          :color="item.status ? statusMap[item.status].color : undefined"
          :dark="item.status ? statusMap[item.status].dark : false"
        >
          <template v-if="item.status">{{ statusMap[item.status].title }}</template>
          <template v-else>Not Started</template>
        </v-chip>
      </template>
      <template v-slot:item.actions>

      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from 'lodash';
import neris from '@/api/neris';
import routes from '@/enums/routes';

export default {
  name: 'IncidentsTable',

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      routes,
      debounce: null,
      pagination: {
        sortDesc: [true],
        sortBy: ['incidentDateTime'],
        page: 1,
        itemsPerPage: 25,
        totalItems: 0,
      },
      items: [],
      totalItems: 0,
      loading: false,
      headers: [
        {
          text: 'Incident Number',
          value: 'incidentNumber',
        },
        {
          text: 'Incident DateTime',
          value: 'incidentDateTime',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Submitted At',
          value: 'submittedAt',
        },
        {
          text: 'Updated At',
          value: 'updatedAt',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      statusMap: {
        incomplete: {
          title: 'Incomplete',
          color: 'blue',
          dark: true,
        },
        submitted: {
          title: 'Submitted',
          color: 'green',
          dark: true,
        },
        changed: {
          title: 'Unsubmitted Changes',
          color: 'blue',
          dark: true,
        },
      },
    };
  },

  watch: {
    /**
     * Watch pagination options and update pagination
     */
    pagination() {
      this.fetchDataDebounce();
    },
  },
  mounted() {
    this.fetchDataDebounce();
  },
  methods: {
    async fetchData() {
      const params = {
        page: this.pagination.page,
        perPage: this.pagination.itemsPerPage,
        orderBy: this.pagination.sortBy[0],
        sortOrder: this.pagination.sortDesc[0] ? 'desc' : 'asc',
        type: this.type,
        ...this.filters,
      };
      try {
        this.loading = true;
        const response = await neris.getIncidents(params);
        this.items = response.data;
        this.totalItems = response.total;
      } finally {
        this.loading = false;
      }
    },

    fetchDataDebounce() {
      if (!this.debounce) {
        const that = this;
        this.debounce = _.debounce(() => {
          that.fetchData();
        }, 300);
      }
      this.debounce();
    },
  },
};
</script>

<style lang="scss" module>
.actions {
  width: 20%;
}
</style>
<style lang="scss">
.neris-incidents-table {
  .v-data-table__wrapper {
    height: calc(100vh - 240px);
  }
  thead {
    th {
      z-index: 1 !important;
    }
  }
}
</style>
