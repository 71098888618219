<template>
  <neris-form
      v-if="structure"
      v-model="formData"
      :structure="structure"
      :pre-defined-categories="preDefinedCategories"
      :error-messages="errorsMessages"
      :empty-objects-to-null="true"
      @input="validate"
  >
    <template v-slot:actions>
      <v-btn
          :disabled="true"
          class="mr-3"
      >
        Cancel
      </v-btn>
      <v-btn
          :loading="false"
          color="primary"
          @click="validateAndSave"
      >
        Save
      </v-btn>
    </template>
  </neris-form>
</template>
<script>
import _ from 'lodash';
import apiv4 from '@/api/apiv4';
import preDefinedCategories from '@/components/Neris/categories';
import structureManagers from '@/components/Neris/Services/structureManagers';
import NerisForm from '@/components/Neris/NerisForm';
import validation from '@/components/Neris/Services/validation';
import formDataMutator from '@/components/Neris/Services/formDataMutator';

/* eslint-disable no-param-reassign */
export default {
  components: {
    NerisForm,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sourceStructure: null,
      formData: null,
      preDefinedCategories: null,
      errorsMessages: {},
    };
  },
  computed: {
    structure() {
      const manager = Object.prototype.hasOwnProperty.call(structureManagers, this.type)
        ? structureManagers[this.type]
        : null;
      return manager
        ? manager.process(_.cloneDeep(this.sourceStructure), this.formData)
        : this.sourceStructure;
    },
  },
  async mounted() {
    this.formData = null;
    this.preDefinedCategories = preDefinedCategories[this.type];
    await this.fetchStructure();
    this.validate();
  },
  methods: {
    validate() {
      let formData = _.cloneDeep(this.formData);
      formData = formDataMutator.mutate(formData, this.structure, this.type);
      this.errorsMessages = validation.validateFormData(formData, this.structure);
      return formData;
    },
    async fetchStructure() {
      this.sourceStructure = await apiv4.get(`/neris/${this.type}`)
        .then(response => response.data);
    },
    async validateAndSave() {
      const formData = this.validate();
      console.log('save', formData);
      await apiv4.post(`/neris/${this.type}`, formData)
        .then(response => response.data);
    },
  },
};
</script>
