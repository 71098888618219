<template>
  <v-list-group
      :sub-group="subGroup"
      :class="{
        'active-item': isActive,
        'no-prepand-icon': nestingNumber > 0,
        'mr-2': nestingNumber === 0,
      }"
      @click="$emit('click')"
  >
    <template v-slot:activator>
      <item-title
          :title="title"
          :required="required"
          :has-errors="hasErrors"
      />
    </template>
    <template v-slot:prependIcon>
      <v-icon v-if="icon">
        {{ nestingNumber === 0 ? icon : '' }}
      </v-icon>
    </template>
    <template v-slot:appendIcon>
      <v-icon>mdi-chevron-down</v-icon>
    </template>

    <item-or-group
        v-for="category in items"
        :key="'navigation_group_item_' + category.id"
        :current-category="currentCategory"
        :category="category"
        :form-data="formData"
        :nesting-number="nestingNumber + 1"
        :error-messages="getErrorMessages(category)"
        @click="chooseCategory(category)"
        @chooseCategory="chooseCategory"
    />
  </v-list-group>
</template>
<script>
import ItemTitle from '@/components/Neris/Navigation/ItemTitle';
import objectUtils from '@/components/Neris/Services/objectUtils';
import navigationMixin from './navigationMixin';

export default {
  name: 'Group',
  mixins: [navigationMixin],
  components: {
    ItemTitle,
    'item-or-group': () => import('@/components/Neris/Navigation/ItemOrGroup'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Object,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      required: false,
    },
    errorMessages: {
      type: [Object, Array],
      required: true,
    },
    hasErrors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    chooseCategory(category) {
      this.$emit('chooseCategory', category);
    },

    getErrorMessages(category) {
      let errorMessages = {};
      if (category.field) {
        const field = category.field.split('.').pop();
        if (!Array.isArray(this.errorMessages)) {
          errorMessages = objectUtils.getByObjectPath(this.errorMessages, field);
        }
      } else {
        console.log('NOT IMPLEMENTED YET');
      }
      return errorMessages || {};
    },
  },
};
</script>
