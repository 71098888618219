<template>
  <div>
    <v-card
      v-if="loading"
      loading
    >
      <v-card-text class="text-center">
        Loading data...
      </v-card-text>
    </v-card>

    <neris-form
        v-if="!loading && initialized && structure"
        :key="'neris_form_' + formId"
        v-model="formData"
        :structure="structure"
        :pre-defined-categories="preDefinedCategories"
        :error-messages="errorsMessages"
        :empty-objects-to-null="true"
        @input="prepareAndValidate"
    >
      <template v-slot:actions>
        <v-btn
            :disabled="saving || discarding"
            :color="isInvalid ? 'primary' : 'green'"
            class="mr-3"
            :dark="!saving && !discarding"
            :light="saving || discarding"
            @click="showValidationDialog"
        >
          <template v-if="saving">
            <v-icon
                light
                left
                class="mdi-spin"
            >mdi-cached</v-icon>
            Validating...
          </template>
          <template v-else>
            <v-icon
                v-if="isInvalid"
                light
                left
            >mdi-alert-outline</v-icon>
            <v-icon
                v-else
                light
                left
            >mdi-check</v-icon>
            Validations
            <template v-if="isInvalid">({{ numberValidationMessages }})</template>
          </template>
        </v-btn>
        <v-btn
            :disabled="!isChanged || saving || discarding || draftSaving"
            class="mr-3"
            @click="cancelChanges"
        >
          Cancel
        </v-btn>
        <v-btn
            :loading="saving"
            :disabled="draftSaving || !frontendValidationPassed || discarding"
            color="primary"
            class="mr-3"
            @click="validateAndSave"
        >
          Validate & Submit
        </v-btn>

        <v-menu
          v-if="draftButtonAsMenu"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :loading="draftSaving || discarding"
                :disabled="saveDraftDisabled && discardDraftDisabled"
                color="primary"
                v-bind="attrs"
                v-on="on"
            >
              Draft
              <v-icon
                  light
                  right
              >mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                :disabled="saveDraftDisabled"
                @click="saveDraft"
            >
              <v-list-item-title>
                Save Draft
              </v-list-item-title>
            </v-list-item>
            <v-list-item
                :disabled="discardDraftDisabled"
                @click="discardDraft"
            >
              <v-list-item-title>
                Discard Draft
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            v-else
            :loading="draftSaving"
            :disabled="saveDraftDisabled"
            color="primary"
            @click="saveDraft"
        >
          Save Draft
        </v-btn>
      </template>
    </neris-form>
    <validation-dialog ref="validationDialog" />
    <validation-passed-dialog ref="validationPassedDialog" />
    <confirmation ref="confirmationPopup"/>
  </div>
</template>
<script>
import _ from 'lodash';
import neris from '@/api/neris';
import preDefinedCategories from '@/components/Neris/categories';
import structureManagers from '@/components/Neris/Services/structureManagers';
import NerisForm from '@/components/Neris/NerisForm';
import validation from '@/components/Neris/Services/validation';
import objectUtils from '@/components/Neris/Services/objectUtils';
import formDataMutator from '@/components/Neris/Services/formDataMutator';
import MUTATOR_TYPES from '@/components/Neris/Services/formDataMutator/mutatorTypes';
import ValidationDialog from '@/components/Neris/Validation/ValidationDialog';
import ValidationPassedDialog from '@/components/Neris/Validation/ValidationPassedDialog';
import { mapActions } from 'vuex';
import { SHOW_SNACKBAR } from '@/store/actions';
import { SET_TITLE } from '@/store/mutations';
import Confirmation from '@/components/Confirmation';

/* eslint-disable no-param-reassign */
export default {
  components: {
    Confirmation,
    ValidationPassedDialog,
    ValidationDialog,
    NerisForm,
  },
  data() {
    return {
      formId: 0,
      initialized: false,
      loading: true,
      draftSaving: false,
      saving: false,
      discarding: false,
      sourceStructure: null,
      incidentNumber: null,
      formDataOrig: null,
      formDataOrigMutated: null,
      formData: null,
      formDataMutated: null,
      dataType: null,
      hasSubmittedData: false,
      preDefinedCategories: null,
      errorsMessages: {},
      serverErrors: {},
    };
  },
  computed: {
    structure() {
      const manager = structureManagers.incident;
      return manager.process(_.cloneDeep(this.sourceStructure), this.formData);
    },
    numberValidationMessages() {
      return this.numberFrontendValidationMessages + this.numberBackendValidationMessages;
    },
    numberFrontendValidationMessages() {
      return validation.countErrors(this.errorsMessages);
    },
    numberBackendValidationMessages() {
      return validation.countServerErrors(this.serverErrors);
    },
    frontendValidationPassed() {
      return this.numberFrontendValidationMessages === 0;
    },
    backendValidationPassed() {
      return this.numberBackendValidationMessages === 0;
    },
    isInvalid() {
      return !this.frontendValidationPassed || !this.backendValidationPassed;
    },
    draftButtonAsMenu() {
      return this.hasSubmittedData;
    },
    isChanged() {
      const data1 = objectUtils.removeNullValues(_.cloneDeep(this.formDataOrigMutated));
      const data2 = objectUtils.removeNullValues(_.cloneDeep(this.formDataMutated));
      return !_.isEqual(data1, data2);
    },
    saveDraftDisabled() {
      return this.saving || !this.isChanged;
    },
    discardDraftDisabled() {
      return this.saving
          || !(this.hasSubmittedData && (this.dataType === 'draft' || this.isChanged));
    },
  },
  async mounted() {
    this.loading = true;
    this.initialized = false;
    this.formDataOrig = null;
    this.formDataOrigMutated = null;
    this.formData = null;
    this.formDataMutated = null;
    this.dataType = null;
    this.hasSubmittedData = false;
    const { incidentNumber } = this.$route.params;
    this.incidentNumber = incidentNumber;
    this.preDefinedCategories = preDefinedCategories.incident;
    this.fetchData(incidentNumber);
    this.$store.commit(SET_TITLE, `NERIS Incident: ${incidentNumber}`);
  },
  methods: {
    ...mapActions({
      showSnackbar: SHOW_SNACKBAR,
    }),
    async fetchData(incidentNumber) {
      try {
        const promises = [
          this.fetchStructure(),
          this.fetchIncidentData(incidentNumber),
        ];
        await Promise.all(promises);
        const formData = this.mutate(this.formData);
        this.validate(formData);
        this.formDataOrig = _.cloneDeep(this.formData);
        this.formDataOrigMutated = _.cloneDeep(formData);
        this.formDataMutated = _.cloneDeep(formData);
        this.initialized = true;
      } catch (e) {
        this.parseErrorResponse(e);
      } finally {
        this.loading = false;
      }
    },
    showValidationDialog() {
      if (this.isInvalid) {
        this.$refs.validationDialog.showDialog(this.errorsMessages, this.serverErrors);
      } else {
        this.$refs.validationPassedDialog.showDialog();
      }
    },
    mutate(formDataOrig) {
      const formData = _.cloneDeep(formDataOrig);
      return formDataMutator.mutate(formData, this.structure, MUTATOR_TYPES.INCIDENT);
    },
    prepareAndValidate() {
      const formData = this.mutate(this.formData);
      this.formDataMutated = formData;
      this.validate(formData);
    },
    validate(formData) {
      this.errorsMessages = validation.validateFormData(formData, this.structure);
    },
    async fetchStructure() {
      this.sourceStructure = await neris.getIncidentStructure();
    },
    async fetchIncidentData(incidentNumber) {
      const response = await neris.getIncidentData(incidentNumber);
      this.formData = response.incidentData;
      this.dataType = response.dataType;
      this.hasSubmittedData = response.hasSubmittedData;
    },
    async validateAndSave() {
      const formData = this.mutate(this.formData);
      this.validate(formData);
      if (!this.frontendValidationPassed) {
        return;
      }
      try {
        this.saving = true;
        this.serverErrors = {};
        await neris.saveIncidentData(this.incidentNumber, formData);
        this.formDataOrig = _.cloneDeep(this.formData);
        this.formDataOrigMutated = _.cloneDeep(formData);
        this.dataType = 'submitted';
      } catch (e) {
        this.parseErrorResponse(e);
        if (!this.backendValidationPassed) {
          this.showValidationDialog();
          this.formDataOrig = _.cloneDeep(this.formData);
          this.formDataOrigMutated = _.cloneDeep(formData);
          this.dataType = 'draft';
        }
      } finally {
        this.saving = false;
      }
    },
    async saveDraft() {
      const formData = this.mutate(this.formData);
      try {
        this.draftSaving = true;
        await neris.saveIncidentDraft(this.incidentNumber, formData);
        this.formDataOrig = _.cloneDeep(this.formData);
        this.formDataOrigMutated = _.cloneDeep(formData);
      } catch (e) {
        this.parseErrorResponse(e);
      } finally {
        this.draftSaving = false;
      }
    },
    parseErrorResponse(error) {
      const { response } = error;
      if (response.status === 400 && response.data.errors !== undefined) {
        this.serverErrors = response.data.errors;
      } else {
        const message = response.data && response.data.message !== undefined
          ? response.data.message
          : null;
        this.showSnackbar({ title: `Unexpected server error${message ? `: ${message}` : ''}`, color: 'red' });
      }
    },
    cancelChanges() {
      this.$refs.confirmationPopup.showConfirm(
        'Cancellation confirmation',
        'This action will discard your local changes and revert '
          + 'the data to its previously saved state. Are you sure?',
        async () => {
          this.formData = _.cloneDeep(this.formDataOrig);
          const formData = this.mutate(this.formDataOrig);
          this.validate(formData);
          this.formDataOrigMutated = _.cloneDeep(formData);
          this.formDataMutated = _.cloneDeep(formData);
          this.refreshForm();
        },
      );
    },
    async discardDraft() {
      this.$refs.confirmationPopup.showConfirm(
        'Discard confirmation',
        'This action will revert the data to the state of the last successfully '
          + 'submitted incident in NERIS. You will lose all changes made to the data '
          + 'after the submission to NERIS.',
        async () => {
          try {
            this.discarding = true;
            const response = await neris.discardDraft(this.incidentNumber);
            this.formData = response.incidentData;
            this.dataType = response.dataType;
            this.hasSubmittedData = response.hasSubmittedData;
            this.formDataOrig = _.cloneDeep(this.formData);
            const formData = this.mutate(this.formData);
            this.formDataMutated = _.cloneDeep(formData);
            this.formDataOrigMutated = _.cloneDeep(formData);
            this.serverErrors = {};
            this.validate(formData);
            this.refreshForm();
          } finally {
            this.discarding = false;
          }
        },
      );
    },
    refreshForm() {
      this.formId += 1;
    },
  },
};
</script>
