<template>
  <v-card>
    <toolbar
      :filters="filters"
      @search="searchIncidents"
    />
    <incidents-table
        ref="incidentsTable"
        :filters="filters"
    />
  </v-card>
</template>
<script>
import IncidentsTable from '@/components/Neris/IncidentsList/IncidentsTable';
import Toolbar from '@/components/Neris/IncidentsList/Toolbar';
import { SET_TITLE } from '@/store/mutations';

export default {
  components: { Toolbar, IncidentsTable },
  data() {
    return {
      loading: true,
      filters: {
        incidentDateRange: null,
        incidentNumber: null,
      },
    };
  },
  methods: {
    searchIncidents(filters) {
      this.filters = filters;
      this.$refs.incidentsTable.fetchDataDebounce();
    },
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'NERIS Incidents');
  },
};
</script>
