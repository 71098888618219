<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          <v-spacer/>
          Validation Passed!
          <v-spacer/>
        </v-card-title>
        <v-card-text сlass="text-center">
          <div class="text-center">
            No validation errors were found.<br />
            Additional validation will be performed on the NERIS side during saving.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            Ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: 'ValidationPassedDialog',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    showDialog() {
      this.dialog = true;
    },
  },
};
</script>
