<template>
  <div>
    <v-dialog
        v-model="dialog"
        max-width="800"
    >
      <v-card>
        <v-card-title>
          Validations ({{ mergedMessagesList.length }}):
        </v-card-title>
        <div style="max-height: calc(100vh - 200px); overflow-y: scroll">
          <v-list
              v-if="mergedMessagesList.length"
              two-line
          >
            <template v-for="(item, index) in mergedMessagesList">
              <v-list-item :key="'error_' + index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.error }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                        v-if="item.type === 'frontend'"
                        color="blue"
                        dark
                        x-small
                    >Local Validation</v-chip>
                    <v-chip
                        v-if="item.type === 'backend'"
                        color="primary"
                        x-small
                    >Server Validation</v-chip>
                    {{ pathFormat(item.path) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                  v-if="index < mergedMessagesList.length - 1"
                  :key="index"
              />
            </template>
          </v-list>
          <v-card-text v-else>No errors</v-card-text>
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import validation from '@/components/Neris/Services/validation';

export default {
  name: 'ValidationDialog',
  mixins: [],
  components: {
  },
  props: {
  },
  data() {
    return {
      dialog: false,
      frontendErrorMessages: {},
      backendErrorMessages: {},
    };
  },
  computed: {
    frontendMessagesList() {
      return validation.getErrorsPlainList(this.frontendErrorMessages);
    },
    backendMessagesList() {
      const errors = [];
      _.forEach(this.backendErrorMessages, (errorsList, path) => {
        errorsList.forEach(error => {
          errors.push({
            error,
            path,
          });
        });
      });
      return errors;
    },
    mergedMessagesList() {
      const mergedList = [];
      this.frontendMessagesList.forEach(item => {
        const errorItem = item;
        errorItem.type = 'frontend';
        mergedList.push(errorItem);
      });
      this.backendMessagesList.forEach(item => {
        const errorItem = item;
        errorItem.type = 'backend';
        mergedList.push(errorItem);
      });
      return mergedList;
    },
  },
  methods: {
    showDialog(frontendErrorMessages, backendErrorMessages) {
      this.frontendErrorMessages = frontendErrorMessages;
      this.backendErrorMessages = backendErrorMessages;
      this.dialog = true;
    },
    pathFormat(path) {
      const parts = path.split('.');
      const formattedParts = parts.map(part => {
        // if part contains number:
        if (part === String(parseInt(part, 10))) {
          return `#${parseInt(part, 10) + 1}`;
        }
        return this.formatFieldName(part);
      });
      return formattedParts.join(' → ');
    },

    formatFieldName(field) {
      return field
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
};
</script>
