/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import objectUtils from '@/components/Neris/Services/objectUtils';
import incidentTypeUtils from '@/components/Neris/Services/incidentTypeUtils';

const nullLocationToEmptyObject = {
  mutate(formData) {
    if (objectUtils.getByObjectPath(formData, 'base.location') === null) {
      formData = objectUtils.setValueByObjectPath(formData, 'base.location', {}, false);
    }
    if (objectUtils.getByObjectPath(formData, 'dispatch.location') === null) {
      formData = objectUtils.setValueByObjectPath(formData, 'dispatch.location', {}, false);
    }
    const exposures = objectUtils.getByObjectPath(formData, 'exposures');
    if (exposures && exposures.length) {
      formData.exposures = exposures.map(exposure => {
        exposure = objectUtils.setValueByObjectPath(exposure, 'location', {}, false);
        return exposure;
      });
    }
    return formData;
  },
};

const cleanUpModulesByIncidentTypes = {
  mutate(formData) {
    const incidentTypes = incidentTypeUtils.getTypes(formData);
    formData = this.mutateFireTypes(formData, incidentTypes);
    formData = this.mutateHazsitTypes(formData, incidentTypes);
    formData = this.mutateMedicalTypes(formData, incidentTypes);
    return formData;
  },

  mutateFireTypes(formData, incidentTypes) {
    const hasFireType = incidentTypeUtils.findIncidentType(incidentTypes, 'FIRE');
    if (!hasFireType) {
      const unsetModules = [
        'fire_detail',
        'smoke_alarm',
        'fire_alarm',
        'other_alarm',
        'fire_suppression',
      ];
      formData = this.cleanUpModules(formData, unsetModules);
    }

    const hasCookingFireSuppressionType = incidentTypeUtils.findIncidentType(
      incidentTypes,
      'FIRE||STRUCTURE_FIRE||CONFINED_COOKING_APPLIANCE_FIRE',
    );

    if (!hasCookingFireSuppressionType) {
      const unsetModules = [
        'cooking_fire_suppression',
      ];
      formData = this.cleanUpModules(formData, unsetModules);
    }
    return formData;
  },

  mutateHazsitTypes(formData, incidentTypes) {
    const hasHazsitType = incidentTypeUtils.findIncidentType(incidentTypes, 'HAZSIT');
    if (!hasHazsitType) {
      const unsetModules = [
        'hazsit_detail',
      ];
      formData = this.cleanUpModules(formData, unsetModules);
    }
    return formData;
  },

  mutateMedicalTypes(formData, incidentTypes) {
    const hasMedicalType = incidentTypeUtils.findIncidentType(incidentTypes, 'MEDICAL');
    if (!hasMedicalType) {
      const unsetModules = [
        'medical_details',
      ];
      formData = this.cleanUpModules(formData, unsetModules);
    }
    return formData;
  },

  cleanUpModules(formData, modulesToCleanUp) {
    modulesToCleanUp.forEach(module => {
      formData = objectUtils.setValueByObjectPath(formData, module, null, false);
    });
    return formData;
  },
};

const incident = {
  mutate(formData, structure) {
    formData = nullLocationToEmptyObject.mutate(formData);
    formData = cleanUpModulesByIncidentTypes.mutate(formData);
    return formData;
  },
};
export default incident;
