<template>
  <div>
    <template
      v-if="fieldProperties.isArray
      && (
          fieldType !== fieldTypes.SELECT
          && fieldType !== fieldTypes.NESTED_SELECT
          && fieldType !== fieldTypes.OBJECT
          && fieldProperties.type !== 'self'
          && !fieldProperties.customImplementation
      )"
    >
      {{ fieldName }} - {{ fieldProperties.type }} - IS NOT ARRAYABLE
    </template>
    <template v-else>
      <v-text-field
          v-if="fieldType === fieldTypes.STRING"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :maxlength="fieldProperties.maxLength || undefined"
          :counter="!!fieldProperties.maxLength"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || false"
          outlined
      />
      <v-textarea
          v-else-if="fieldType === fieldTypes.TEXTAREA"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :maxlength="fieldProperties.maxLength || undefined"
          :counter="!!fieldProperties.maxLength"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || false"
          outlined
      />
      <select-field
          v-else-if="fieldType === fieldTypes.SELECT"
          v-model="innerValue"
          :label="label"
          :items="selectValues"
          :multiple="fieldProperties.isArray"
          :chips="fieldProperties.isArray"
          :deletable-chips="fieldProperties.isArray"
          :error-messages="errorMessages"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || undefined"
          outlined
          clearable
      />
      <nested-select-field
          v-else-if="fieldType === fieldTypes.NESTED_SELECT"
          v-model="innerValue"
          :label="label"
          :items="selectValues"
          :multiple="fieldProperties.isArray"
          :error-messages="errorMessages"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || false"
          outlined
          clearable
      />
      <integer-field
          v-else-if="fieldType === fieldTypes.INTEGER"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || false"
          outlined
      />
      <v-text-field
          v-else-if="fieldType === fieldTypes.FLOAT"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || false"
          type="number"
          outlined
      />
      <boolean-field
          v-else-if="fieldType === fieldTypes.BOOLEAN"
          v-model="innerValue"
          :field-properties="fieldProperties"
          :label="label"
          :error-messages="errorMessages"
          :hint="fieldProperties.description || undefined"
          :disabled="fieldProperties.disabled || false"
      />
      <date-time-field
          v-else-if="fieldType === fieldTypes.DATETIME"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :disabled="fieldProperties.disabled || false"
          outlined
      />
      <basic-form
          v-else-if="fieldType === fieldTypes.OBJECT
          && fieldProperties.structure"
          v-model="innerValue"
          :structure="fieldProperties.structure"
          :field-properties="fieldProperties"
          :multiple="fieldProperties.isArray"
          :error-messages="errorMessages"
          :empty-objects-to-null="true"
          :label="label"
          :nesting-number="nestingNumber"
          :wrapped="wrappable"
      />
      <discriminator
          v-else-if="fieldType === fieldTypes.DISCRIMINATOR"
          v-model="innerValue"
          :field-properties="fieldProperties"
          :label="label"
          :error-messages="errorMessages"
          :nesting-number="nestingNumber"
          :empty-objects-to-null="true"
      />
      <template v-else-if="fieldType === fieldTypes.OBJECT && !fieldProperties.structure">
        <!--Empty object (no fields to render)-->
      </template>
      <template v-else-if="fieldProperties.type === 'self'">
        <!--Self Object is not supported yet-->
      </template>
      <div
       v-else
       class="mb-2 mt-2"
      >
        {{ fieldName }} - {{ fieldProperties.type }} - NOT IMPLEMENTED YET
      </div>
   </template>
 </div>
</template>
<script>
import BooleanField from '@/components/Neris/Controls/BooleanField';
import IntegerField from '@/components/Neris/Controls/IntegerField';
import DateTimeField from '@/components/Neris/Controls/DateTimeField';
import NestedSelectField from '@/components/Neris/Controls/NestedSelectField';
import FIELD_TYPES from '@/components/Neris/fieldTypes';
import SelectField from '@/components/Neris/Controls/SelectField';
import namings from '@/components/Neris/Services/namings';

export default {
  name: 'DynamicField',
  components: {
    SelectField,
    DateTimeField,
    IntegerField,
    BooleanField,
    NestedSelectField,
    'basic-form': () => import('@/components/Neris/BasicForm'),
    discriminator: () => import('@/components/Neris/Discriminator'),
  },
  data() {
    return {
      fieldTypes: FIELD_TYPES,
    };
  },
  props: {
    value: {
      required: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    fieldProperties: {
      type: Object,
      required: true,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: [Object, Array],
      required: true,
    },
  },
  emits: ['input'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', this.prepareValue(value));
      },
    },
    fieldType() {
      let fieldType = null;
      if (this.fieldProperties.type === 'string'
         && (!this.fieldProperties.maxLength || this.fieldProperties.maxLength < 500)
      ) {
        fieldType = this.fieldTypes.STRING;
      } else if (this.fieldProperties.type === 'string'
         && (this.fieldProperties.maxLength && this.fieldProperties.maxLength >= 500)
      ) {
        fieldType = this.fieldTypes.TEXTAREA;
      } else if (this.fieldProperties.type === 'enum') {
        fieldType = this.fieldTypes.SELECT;
      } else if (this.fieldProperties.type === 'int') {
        fieldType = this.fieldTypes.INTEGER;
      } else if (this.fieldProperties.type === 'float') {
        fieldType = this.fieldTypes.FLOAT;
      } else if (this.fieldProperties.type === 'bool') {
        fieldType = this.fieldTypes.BOOLEAN;
      } else if (this.fieldProperties.type === 'datetime') {
        fieldType = this.fieldTypes.DATETIME;
      } else if (this.fieldProperties.type === 'datetime') {
        fieldType = this.fieldTypes.DATETIME;
      } else if (this.fieldProperties.type === 'object') {
        fieldType = this.fieldTypes.OBJECT;
      } else if (this.fieldProperties.type === 'discriminator') {
        fieldType = this.fieldTypes.DISCRIMINATOR;
      }
      if (fieldType === this.fieldTypes.SELECT && this.fieldProperties.isNestedValues) {
        fieldType = this.fieldTypes.NESTED_SELECT;
      }
      return fieldType;
    },
    selectValues() {
      return this.fieldProperties.values;
    },
    label() {
      let label = namings.fieldName(this.fieldProperties, this.fieldName);
      if (this.fieldProperties.required && !this.fieldProperties.noValidate) {
        label += ' (required)';
      }
      return label;
    },
    wrappable() {
      let wrappable = this.fieldProperties.type === 'object';
      if (this.fieldProperties.type === 'object'
          && Object.prototype.hasOwnProperty.call(this.fieldProperties, 'wrappable')
      ) {
        wrappable = this.fieldProperties.wrappable;
      }
      return wrappable;
    },
  },
  methods: {
    prepareValue(value) {
      if (Array.isArray(value)) {
        return value.length ? value : null;
      }
      return value || (typeof value === 'boolean') ? value : null;
    },
  },
};
</script>
