import _ from 'lodash';
import namings from '@/components/Neris/Services/namings';

const validationLogic = {
  validateFormData(formData, structure) {
    let errorMessages = {};
    _.forEach(structure, (fieldProperties, field) => {
      let fieldErrorMessages = {};
      if (fieldProperties.type === 'object') {
        if (fieldProperties.isArray) {
          fieldErrorMessages = this.validateArrayField(field, fieldProperties, formData);
        } else {
          fieldErrorMessages = this.validateObjectField(field, fieldProperties, formData);
        }
      } else if (fieldProperties.type === 'discriminator') {
        fieldErrorMessages = this.validateDiscriminatorField(field, fieldProperties, formData);
      } else {
        fieldErrorMessages = this.validatePrimitiveTypeFields(field, fieldProperties, formData);
      }
      errorMessages = { ...errorMessages, ...fieldErrorMessages };
    });
    return errorMessages;
  },

  addErrorMessage(errorMessages, message) {
    let messages = errorMessages;
    if (!messages || !Array.isArray(messages)) {
      messages = [];
    }
    messages.push(message);
    return messages;
  },

  validateDiscriminatorField(fieldName, fieldProperties, formData) {
    const errorMessages = {};
    if (fieldProperties.hidden) {
      return errorMessages;
    }
    const discriminatorFormData = formData && formData[fieldName] ? formData[fieldName] : null;
    const type = discriminatorFormData ? discriminatorFormData.type : null;
    if (type) {
      const { mapping } = fieldProperties.discriminator;
      const objectName = mapping[type];
      const discriminatorStructure = fieldProperties.discriminator.objects[objectName];
      const messages = this.validateFormData(
        discriminatorFormData,
        discriminatorStructure,
      );
      if (Object.keys(messages).length) {
        errorMessages[fieldName] = messages;
      }
    } else if (fieldProperties.required) {
      if (!errorMessages[fieldName]) {
        errorMessages[fieldName] = {};
      }
      errorMessages[fieldName].type = this.addErrorMessage(
        errorMessages[fieldName].type,
        `The "Type ${namings.fieldName(fieldProperties, fieldName)}" field is required`,
      );
    }
    return errorMessages;
  },

  validatePrimitiveTypeFields(fieldName, fieldProperties, formData) {
    const errorMessages = {};
    if (fieldProperties.hidden) {
      return errorMessages;
    }
    if (
      fieldProperties.required
      && !fieldProperties.noValidate
      && (!formData || formData[fieldName] === null || formData[fieldName] === undefined)
    ) {
      errorMessages[fieldName] = this.addErrorMessage(
        errorMessages[fieldName],
        `The "${namings.fieldName(fieldProperties, fieldName)}" field is required`,
      );
    }
    if (
      fieldProperties.type === 'string'
      && fieldProperties.pattern
      && !fieldProperties.noValidate
      && formData
      && formData[fieldName]
    ) {
      const pattern = new RegExp(fieldProperties.pattern);
      if (!pattern.test(formData[fieldName])) {
        let message = `The "${namings.fieldName(fieldProperties, fieldName)}" is invalid.`;
        if (fieldProperties.patternFormat) {
          message += ` It must match the format: "${fieldProperties.patternFormat}".`;
        }
        errorMessages[fieldName] = this.addErrorMessage(
          errorMessages[fieldName],
          message,
        );
      }
    }
    return errorMessages;
  },

  validateArrayField(fieldName, fieldProperties, formData) {
    const errorMessages = {};
    if (fieldProperties.hidden) {
      return errorMessages;
    }
    if (
      fieldProperties.required
      && (!formData || !formData[fieldName] || !formData[fieldName].length)
    ) {
      errorMessages[fieldName] = this.addErrorMessage(
        errorMessages[fieldName],
        `The "${namings.fieldName(fieldProperties, fieldName)}" field is required`,
      );
    } else if (formData && formData[fieldName]) {
      errorMessages[fieldName] = [];
      formData[fieldName].forEach((formDataItem, index) => {
        const messages = this.validateFormData(
          formDataItem,
          fieldProperties.structure,
        );
        if (Object.keys(messages).length) {
          errorMessages[fieldName][index] = messages;
        }
      });
    }
    return errorMessages;
  },

  validateObjectField(fieldName, fieldProperties, formData) {
    const errorMessages = {};
    if (fieldProperties.hidden) {
      return errorMessages;
    }
    if (fieldProperties.required) {
      const messages = this.validateFormData(
        formData ? formData[fieldName] || {} : {},
        fieldProperties.structure,
      );
      const countMessages = Object.keys(messages).length;
      if (!countMessages && (!formData || !formData[fieldName])) {
        errorMessages[fieldName] = this.addErrorMessage(
          errorMessages[fieldName],
          `The "${namings.fieldName(fieldProperties, fieldName)}" schema is required`,
        );
      } else if (countMessages) {
        errorMessages[fieldName] = messages;
      }
    } else if (formData && formData[fieldName]) {
      errorMessages[fieldName] = this.validateFormData(
        formData ? formData[fieldName] || {} : {},
        fieldProperties.structure,
      );
    }
    return errorMessages;
  },
};

const validation = {
  validateFormData(formData, structure) {
    return validationLogic.validateFormData(formData, structure);
  },

  countErrors(errorMessages) {
    let count = 0;
    _.forEach(errorMessages, (value) => {
      if (Array.isArray(value)) {
        count += this.countErrors(value);
      } else if (typeof value === 'string') {
        count++;
      } else if (value !== null && typeof value === 'object') {
        count += this.countErrors(value);
      }
    });
    return count;
  },

  countServerErrors(errorMessages) {
    let count = 0;
    _.forEach(errorMessages, (errors) => {
      count += errors.length;
    });
    return count;
  },

  getErrorsPlainList(errorMessages, path = '', isArrayElement = false) {
    let errors = [];
    _.forEach(errorMessages, (value, field) => {
      const fieldPath = path ? `${path}.${field}` : field;
      let nestedErrors = [];
      if (Array.isArray(value)) {
        nestedErrors = this.getErrorsPlainList(value, fieldPath, true);
      } else if (typeof value === 'string') {
        errors.push({
          error: value,
          path: isArrayElement ? path : fieldPath,
        });
      } else if (value !== null && typeof value === 'object') {
        nestedErrors = this.getErrorsPlainList(value, fieldPath, false);
      }
      if (nestedErrors.length) {
        errors = errors.concat(nestedErrors);
      }
    });
    return errors;
  },
};

export default validation;
