/* eslint-disable no-param-reassign */
import incident from '@/components/Neris/Services/formDataMutator/incident';
import entity from '@/components/Neris/Services/formDataMutator/entity';
import common from '@/components/Neris/Services/formDataMutator/common';

const mutators = {
  common,
  incident,
  entity,
};

const index = {
  mutate(formData, structure, type = null) {
    const commonMutator = mutators.common;
    const typeMutator = type && Object.prototype.hasOwnProperty.call(mutators, type)
      ? mutators[type]
      : null;
    formData = commonMutator.mutate(formData, structure);
    if (typeMutator) {
      formData = typeMutator.mutate(formData, structure);
    }
    return formData;
  },
};
export default index;
