var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"none"}},[_c('div',{attrs:{"id":_vm.uniqueId}},[_c('div',{staticClass:"print-images"},[_vm._l((_vm.images),function(url,index){return [_c('img',{key:'img_' + index,style:({
              position: 'relative',
              pageBreakInside: 'avoid',
              pageBreakAfter: 'avoid',
              padding: _vm.isSafari ? 0 : '10mm 5mm 5mm 10mm',
              top: 0,
              left: _vm.isSafari ? '15px' : 0,
              right: 0,
              bottom: 0,
              border: 0,
              maxWidth: _vm.isSafari ? '95% !important' : '98% !important',
              margin: '0 auto !important',
            }),attrs:{"src":url},on:{"load":function($event){return _vm.imageLoaded(url)}}}),(index < _vm.images.length-1)?_c('div',{key:'pagebreak_' + index,staticStyle:{"page-break-before":"always"}}):_vm._e()]})],2)]),_c('div',{attrs:{"id":_vm.uniqueId + '_narcotic_request'}},[_c('div',{staticClass:"print-images-narcotic-request"},[_vm._l((_vm.images),function(url,index){return [_c('img',{key:'img_' + index,style:({
              position: 'relative',
              pageBreakInside: 'avoid',
              pageBreakAfter: 'avoid',
              padding: _vm.isSafari ? 0 : '10mm 5mm 5mm 10mm',
              top: 0,
              left: _vm.isSafari ? '15px' : 0,
              right: 0,
              bottom: 0,
              border: 0,
              maxWidth: _vm.isSafari ? '95% !important' : '98% !important',
              margin: '0 auto !important',
            }),attrs:{"src":url},on:{"load":function($event){return _vm.imageLoaded(url)}}}),(index < _vm.images.length-1)?_c('div',{key:'pagebreak_' + index,staticStyle:{"page-break-before":"always"}}):_vm._e()]}),_c('img',{staticStyle:{"position":"absolute !important","page-break-inside":"avoid","page-break-after":"always","padding-top":"220px","top":"0","left":"0","right":"0","bottom":"0","border":"0","z-index":"100","margin":"0 auto"},attrs:{"src":"/spa/images/watermarking.png"},on:{"load":function($event){return _vm.imageLoaded('watermark')}}})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }