<template>
  <div v-if="initialized">
    <template v-if="customImplementation">
      <custom-form
          v-model="formData"
          :structure="structure"
          :field-properties="fieldProperties"
          :error-messages.sync="errorMessages"
          :label="label"
          :empty-objects-to-null="true"
      />
    </template>

    <template v-else-if="wrapped">
      <wrapped-form
          v-model="formData"
          :structure="structure"
          :field-properties="fieldProperties"
          :label="label"
          :show-toolbar="true"
          :multiple="multiple"
          :nesting-number="nestingNumber + 1"
          :error-messages="errorMessages"
          :empty-objects-to-null="true"
          outlined
      />
    </template>

    <template v-else-if="multiple">
      <span v-if="!formData || !formData.length">
        No added items
      </span>
      <v-container v-if="formData && formData.length">
        <v-row
            v-for="(item, index) in formData"
            v-bind:key="'nested_object_form_row' + index + '_' + keyForceReload"
            class="align-start"
        >
          <div
            class="mt-4"
            style="width: 32px"
          >
            <v-icon
                v-if="isDialog
                  && errorMessagesModel
                  && hasErrorRecursive(errorMessagesModel[index])"
                class="inline_span"
                color="red"
                dense
            >
              mdi-alert-circle
            </v-icon>
          </div>
          <div class="mr-3 mt-4"><b>{{ index + 1 }}.</b></div>

          <dialog-form
              v-if="isDialog"
              v-model="formData[index]"
              class="flex-grow-1"
              :title="dialogTitle"
              :dialog-view-type="dialogType"
              :structure="structure"
              :error-messages="errorMessagesModel[index] || {}"
          />
          <wrapped-form
              v-else
              v-model="formData[index]"
              :structure="structure"
              :field-properties="fieldProperties"
              :multiple="false"
              :nesting-number="nestingNumber + 1"
              :show-toolbar="false"
              :error-messages="errorMessagesModel[index] || {}"
              class="flex-grow-1"
          />
          <v-btn
              color="red"
              text
              class="ml-2 mt-2"
              icon
              @click="deleteItem(index)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </template>

    <template v-else>
      <div
          v-for="(item, fieldName) in structure"
          v-bind:key="'field_' + fieldName"
      >
        <dynamic-field
            v-if="!item.hidden"
            v-model="formData[fieldName]"
            :field-properties="item"
            :field-name="fieldName"
            :nesting-number="nestingNumber"
            :error-messages="errorMessagesModel[fieldName] || []"
        />
      </div>
    </template>
  </div>
</template>
<script>

import _ from 'lodash';
import DynamicField from '@/components/Neris/DynamicField';
import formDataMixin from '@/components/Neris/mixins/formDataMixin';
import formsMixin from '@/components/Neris/mixins/formsMixin';
import basicFormsMixin from '@/components/Neris/mixins/basicFormsMixin';
import CustomForm from '@/components/Neris/CustomForm';

export default {
  name: 'BasicForm',
  mixins: [formDataMixin, formsMixin, basicFormsMixin],

  components: {
    CustomForm,
    DynamicField,
    'wrapped-form': () => import('@/components/Neris/WrappedForm'),
    'dialog-form': () => import('@/components/Neris/DialogForm'),
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    wrapped: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialized: false,
      errorMessagesModel: [],
      keyForceReload: 0,
    };
  },
  computed: {
    isDialog() {
      return this.fieldProperties.isDialog;
    },
    dialogType() {
      return this.isDialog && this.fieldProperties.class
        ? this.fieldProperties.class
        : null;
    },
    dialogTitle() {
      return this.isDialog
        ? this.fieldProperties.dialogTitle || ''
        : null;
    },
    customImplementation() {
      let customImplementation = false;
      if (this.fieldProperties.type === 'object'
          && Object.prototype.hasOwnProperty.call(this.fieldProperties, 'customImplementation')
      ) {
        customImplementation = this.fieldProperties.customImplementation;
      }
      return customImplementation;
    },
  },
  watch: {
    errorMessages: {
      handler(value) {
        this.errorMessagesModel = value;
      },
      deep: true,
    },
  },
  mounted() {
    const blankData = this.multiple ? [] : {};
    this.formData = this.value ? _.cloneDeep(this.value) : blankData;
    this.errorMessagesModel = this.errorMessages;
    this.initialized = true;
  },
  methods: {
    hasErrorRecursive(errorMessages) {
      if (!errorMessages) {
        return false;
      }
      let hasErrors = false;
      _.forEach(errorMessages, (item) => {
        if (typeof item === 'string') {
          hasErrors = true;
        } else {
          const hasErrorNested = this.hasErrorRecursive(item);
          if (hasErrorNested) {
            hasErrors = true;
          }
        }
      });
      return hasErrors;
    },

    addItem() {
      let formData = _.cloneDeep(this.formData);
      if (!formData) {
        formData = [];
      }
      if (formData.length === 0) {
        this.errorMessagesModel = [];
        this.$emit('update:errorMessages', []);
      }
      formData.push(null);
      this.formData = formData;
    },
    deleteItem(index) {
      const formData = _.cloneDeep(this.formData);
      formData.splice(index, 1);
      this.formData = formData;

      if (index in this.errorMessagesModel) {
        const errorMessagesModel = _.cloneDeep(this.errorMessagesModel);
        errorMessagesModel.splice(index, 1);
        this.errorMessagesModel = errorMessagesModel;
        this.$emit('update:errorMessages', errorMessagesModel);
      }
      this.keyForceReload++;
    },
  },
};
</script>
