/* eslint func-names:0 */
import objectUtils from '@/components/Neris/Services/objectUtils';

export default {
  props: {
    formData: {
      type: Object,
      required: false,
    },
  },
  methods: {
    countSubcategories(category) {
      if (!category.subcategories) {
        return 0;
      }
      return Object.keys(category.subcategories).length;
    },

    getCountByObjectPath(path) {
      const value = this.formData ? objectUtils.getByObjectPath(this.formData, path) : null;
      if (value) {
        return value.length;
      }
      return 0;
    },
  },
};
