import api from './api';

export default {
  /**
   * Fetch incident structure.
   *
   * @return Promise<Object>
   */
  getIncidents(params) {
    return api()
      .get('/neris/incidents', { params })
      .then(response => response.data);
  },

  /**
     * Fetch incident structure.
     *
     * @return Promise<Object>
     */
  getIncidentStructure() {
    return api()
      .get('/neris/incidents/structure')
      .then(response => response.data);
  },

  /**
   * Fetch incident data.
   *
   * @param {String} incidentNumber - Incident number
   *
   * @return Promise<Object>
   */
  getIncidentData(incidentNumber) {
    return api()
      .get(`/neris/incidents/${incidentNumber}`)
      .then(response => response.data);
  },

  /**
   * Save incident data draft.
   *
   * @param {String} incidentNumber - Incident number
   * @param {Object} data - Incident data
   *
   * @return Promise<Object>
   */
  saveIncidentDraft(incidentNumber, data) {
    return api()
      .post(`/neris/incidents/${incidentNumber}/draft`, data)
      .then(response => response.data);
  },

  /**
   * Discard draft data.
   *
   * @param {String} incidentNumber - Incident number
   *
   * @return Promise<Object>
   */
  discardDraft(incidentNumber) {
    return api()
      .delete(`/neris/incidents/${incidentNumber}/draft`)
      .then(response => response.data);
  },

  /**
   * Save incident data.
   *
   * @param {String} incidentNumber - Incident number
   * @param {Object} data - Incident data
   *
   * @return Promise<Object>
   */
  saveIncidentData(incidentNumber, data) {
    return api()
      .post(`/neris/incidents/${incidentNumber}`, data)
      .then(response => response.data);
  },
};
