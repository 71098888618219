import Vue from 'vue';
import Router from 'vue-router';
import LongBeachBilling from '@/views/LongBeachBilling';
import Login from '@/views/Login';
import Forgot from '@/views/Forgot';
import ForgotChangePassword from '@/views/ForgotChangePassword';
import CadMonitoringViewResolver from '@/views/CadMonitoring/CadMonitoringViewResolver';
import Hospitals from '@/views/Hospitals';
import HospitalsTypes from '@/views/HospitalsTypes';
import Templates from '@/views/Templates';
import Admin from '@/views/Admin';
import ApiAuditLog from '@/views/ApiAuditLog';
import UtilitiesExport from '@/views/UtilitiesExport';
import LastImportedIncidentsViewResolver from '@/views/LastImportedIncidents/LastImportedIncidentsViewResolver';
import FlaggedUsersViewResolver from '@/views/FlaggedUsers/FlaggedUsersViewResolver';
import OdMapExportsViewResolver from '@/views/OdMapExports/OdMapExportsViewResolver';
import MaterialsViewResolver from '@/views/Materials/MaterialsViewResolver';
import DuplicateIncidents from '@/views/DuplicateIncidents';
import PushNotifications from '@/views/PushNotifications';
import ReleaseNotes from '@/views/ReleaseNotes';
import Utilities from '@/views/Utilities';
import Clients from '@/views/Clients';
import Users from '@/views/Users';
import Faxes from '@/views/Faxes';
import Devices from '@/views/Devices';
import FirstRunDevices from '@/views/FirstRunDevices';
import ProfileSettings from '@/views/ProfileSettings';
import ErrorsUtility from '@/views/ErrorsUtility';
import ImpersonateAndRedirect from '@/views/ImpersonateAndRedirect';
import HDEExport from '@/views/HDEExport';
import DashboardViewResolver from '@/views/Dashboard/DashboardViewResolver';
import Incidents from '@/views/Incidents';
import IncidentApForms from '@/views/IncidentApForms';
import TransactionLogs from '@/views/TransactionLogs';
import HospitalLogs from '@/views/HospitalLogs';
import TransmittedReports from '@/views/TransmittedReports';
import IncidentMonitors from '@/views/IncidentMonitors';
import Insights from '@/views/Insights';
import ExportsLog from '@/views/ExportsLog';
import Reconciliation from '@/views/Reconciliation';
import NfirsExport from '@/views/NfirsExport';
import Invoices from '@/views/Invoices';
import IncidentSystemSearch from '@/views/IncidentSystemSearch';
import HospitalIncidents from '@/views/HospitalIncidents';
import IncidentViewResolver from '@/views/IncidentView/IncidentViewResolver';
import MyHospital from '@/views/MyHospital';
import BaseCeEntry from '@/views/CeEntry/BaseCeEntry';
import SEARCH_TYPES from '@/enums/searchTypes';
import TRANSMITTED_TYPES from '@/enums/transmittedTypes';
import NemsisExportViewResolver from '@/views/NemsisExport/NemsisExportViewResolver';
import NerisPrototype from '@/views/NerisPrototype';
import NerisIncident from '@/views/Neris/NerisIncident';
import NerisIncidents from '@/views/Neris/NerisIncidents';
import store from '@/store';
import CLIENT_SITE_TYPES from '@/enums/clientSiteTypes';
import CeHome from '@/views/CeEntry/CeHome';
import { createAclRule, getConditionObject } from './aclRuleService';
import EXPORT_TYPES from '../enums/exportTypes';
import NEMSIS_EXPORT_TYPES from '../enums/nemsisExportTypes';
import ROUTES from '../enums/routes';
import ACL from '../enums/aclGlobalRules';
import USER_ROLES from '../enums/userRoles';

Vue.use(Router);

createAclRule([
  USER_ROLES.SUPER_ADMIN,
  USER_ROLES.CLIENT_QA_USER,
  getConditionObject('client_type_regular', [
    USER_ROLES.CLIENT_ADMIN,
    USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
    USER_ROLES.CLIENT_QA_USER,
  ]),
  [
    'client_type_regular',
    USER_ROLES.CLIENT_ADMIN,
  ],
]);

const createRouter = () => new Router({
  mode: 'history',
  fallback: false,
  routes: [
    {
      path: '/neris/entity',
      name: ROUTES.NERIS_ENTITY_PROTOTYPE,
      component: NerisPrototype,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { type: 'entity' },
    },
    {
      path: '/neris/incident',
      name: ROUTES.NERIS_INCIDENT_PROTOTYPE,
      component: NerisPrototype,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { type: 'incident' },
    },
    {
      path: '/neris/incidents',
      name: ROUTES.NERIS_INCIDENTS,
      component: NerisIncidents,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { type: 'incident' },
    },
    {
      path: '/neris/incidents/:incidentNumber',
      name: ROUTES.NERIS_INCIDENT,
      component: NerisIncident,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
    },
    {
      path: '/dashboard',
      name: ROUTES.DASHBOARD,
      component: DashboardViewResolver,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_QA_USER,
          ]),
        ]),
      },
    },
    {
      path: '/users',
      name: ROUTES.USERS,
      component: Users,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          USER_ROLES.CLIENT_CAPTAIN_USER,
          USER_ROLES.HOSPITAL_ADMIN,
          USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED,
        ]),
      },
    },
    {
      path: '/ce-home',
      name: ROUTES.CE_HOME,
      component: CeHome,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.CE_ENTRY, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
            USER_ROLES.CLIENT_CE_USER,
          ]),
        ]),
      },
    },
    {
      path: '/ce-entry',
      name: ROUTES.CE_ENTRY,
      component: BaseCeEntry,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          USER_ROLES.CLIENT_CAPTAIN_USER,
          USER_ROLES.CLIENT_FIREFIGHTER_USER,
          USER_ROLES.CLIENT_CE_USER,
        ]),
      },
      props: { tab: 'myLicense' },
      beforeEnter: (to, from, next) => {
        if (store().state.user.ceHoursEnabled) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/ce-entry/agency-overview',
      name: ROUTES.CE_ENTRY_AGENCY_OVERVIEW,
      component: BaseCeEntry,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { tab: 'agencyOverview' },
    },
    {
      path: '/ce-entry/skills-overview',
      name: ROUTES.CE_ENTRY_SKILLS_OVERVIEW,
      component: BaseCeEntry,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { tab: 'skillsOverview' },
    },
    {
      path: '/ce-entry/agency-overview/:id',
      name: ROUTES.CE_ENTRY_AGENCY_OVERVIEW_USER,
      component: BaseCeEntry,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { tab: 'agencyOverview' },
    },
    {
      path: '/ce-entry/entries',
      name: ROUTES.CE_ENTRY_ENTRIES,
      component: BaseCeEntry,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          USER_ROLES.CLIENT_CAPTAIN_USER,
          USER_ROLES.CLIENT_FIREFIGHTER_USER,
          USER_ROLES.CLIENT_CE_USER,
        ]),
      },
      props: { tab: 'entries' },
      beforeEnter: (to, from, next) => {
        if (store().state.user.ceHoursEnabled) {
          next();
        } else {
          next('/login');
        }
      },
    },
    {
      path: '/ce-entry/settings',
      name: ROUTES.CE_SETTINGS,
      component: BaseCeEntry,
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
        ]),
      },
      props: { tab: 'settings' },
    },
    {
      path: '/users/deleted',
      name: ROUTES.USERS_DELETED,
      component: Users,
      props: { isDeleted: true },
      meta: {
        rule: createAclRule([
          USER_ROLES.CLIENT_ADMIN,
          USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          USER_ROLES.CLIENT_CAPTAIN_USER,
        ]),
      },
    },
    {
      path: '/clients',
      name: ROUTES.CLIENTS,
      component: Clients,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/admins',
      name: ROUTES.ADMINS,
      component: Admin,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/hospitals',
      name: ROUTES.HOSPITALS,
      component: Hospitals,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/templates',
      name: ROUTES.TEMPLATES,
      component: Templates,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/hospitals/types',
      name: ROUTES.HOSPITAL_TYPES,
      component: HospitalsTypes,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/materials',
      name: ROUTES.MATERIALS,
      component: MaterialsViewResolver,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
    },
    {
      path: '/invoicing',
      name: ROUTES.INVOICES,
      component: Invoices,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/incidents',
      name: ROUTES.HOSPITAL_INCIDENTS,
      component: HospitalIncidents,
      meta: {
        rule: ACL.HOSPITAL_USER,
      },
    },
    {
      path: '/log',
      name: ROUTES.HOSPITAL_INCIDENTS_LOG,
      component: HospitalLogs,
      meta: {
        rule: createAclRule([
          USER_ROLES.HOSPITAL_ADMIN,
          USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED,
        ]),
      },
    },
    {
      path: '/hospital',
      name: ROUTES.MY_HOSPITAL,
      component: MyHospital,
      meta: {
        rule: ACL.HOSPITAL_USER,
      },
    },
    {
      path: '/incidents/regular',
      name: ROUTES.INCIDENTS_REGULAR,
      component: Incidents,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
            USER_ROLES.CLIENT_QA_USER,
          ]),
        ]),
      },
      props: { type: SEARCH_TYPES.REGULAR },
    },
    {
      path: '/incidents/flagged',
      name: ROUTES.INCIDENTS_FLAGGED,
      component: Incidents,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
      props: { type: SEARCH_TYPES.FLAGGED },
    },
    {
      path: '/incidents/unfinished',
      name: ROUTES.INCIDENTS_UNFINISHED,
      component: Incidents,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
      props: { type: SEARCH_TYPES.UNFINISHED },
    },
    {
      path: '/incidents/deleted',
      name: ROUTES.INCIDENTS_DELETED,
      component: Incidents,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
      props: { type: SEARCH_TYPES.DELETED },
    },
    {
      path: '/incidents/ap-forms',
      name: ROUTES.INCIDENTS_AP_FORMS,
      component: IncidentApForms,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_AP_USER,
          ]),
        ]),
      },
    },
    {
      path: '/incidents/log',
      name: ROUTES.INCIDENTS_LOG,
      component: TransactionLogs,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
    },
    {
      path: '/incidents/transmitted',
      name: ROUTES.INCIDENTS_TRANSMITTED,
      component: TransmittedReports,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
      props: { type: TRANSMITTED_TYPES.REMOTE_HANDOFF },
    },
    {
      path: '/incidents/airdropped',
      name: ROUTES.INCIDENTS_AIRDROPPED,
      component: TransmittedReports,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
      props: { type: TRANSMITTED_TYPES.AIRDROP },
    },
    {
      path: '/incidents/monitors',
      name: ROUTES.INCIDENTS_MONITORS,
      component: IncidentMonitors,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_QA_USER,
          ]),
        ]),
      },
    },
    {
      path: '/incidents/insights',
      name: ROUTES.INSIGHTS,
      component: Insights,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_QA_USER,
          ]),
        ]),
      },
    },
    {
      path: '/incidents/:id',
      name: ROUTES.INCIDENT_VIEW,
      component: IncidentViewResolver,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_QA_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
          USER_ROLES.HOSPITAL_ADMIN,
          USER_ROLES.HOSPITAL_ADMIN_IMPERSONATED,
          USER_ROLES.HOSPITAL_USER,
        ]),
      },
    },
    {
      path: '/devices',
      name: ROUTES.DEVICES,
      component: Devices,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
          ]),
        ]),
      },
    },
    {
      path: '/profileSettings',
      name: ROUTES.PROFILE_SETTINGS,
      component: ProfileSettings,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
    },
    {
      path: '/login',
      name: ROUTES.LOGIN,
      component: Login,
      meta: {
        rule: ACL.PUBLIC,
      },
    },
    {
      path: '/forgot',
      name: ROUTES.FORGOT,
      component: Forgot,
      meta: {
        rule: ACL.PUBLIC,
      },
    },
    {
      path: '/forgot/:token',
      name: ROUTES.FORGOT_CHANGE_PASSWORD,
      component: ForgotChangePassword,
      meta: {
        rule: ACL.PUBLIC,
      },
    },
    {
      path: '/Utilities/Reconciliation',
      name: ROUTES.RECONCILIATION,
      component: Reconciliation,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
    },
    {
      path: '/Utilities/LongBeachBilling',
      name: ROUTES.LONG_BEACH_BILLING,
      component: LongBeachBilling,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/NfirsExport',
      name: ROUTES.NFIRS_EXPORT,
      component: NfirsExport,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
    },
    {
      path: '/Utilities/CadMonitoring',
      name: ROUTES.CAD_MONITORING,
      component: CadMonitoringViewResolver,
      meta: {
        rule: ACL.PUBLIC,
      },
    },
    {
      path: '/Utilities/ApiAuditLog',
      name: ROUTES.API_AUDIT,
      component: ApiAuditLog,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/LastImportedIncidents',
      name: ROUTES.LAST_IMPORTED_INCIDENTS,
      component: LastImportedIncidentsViewResolver,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
    },
    {
      path: '/Utilities/FlaggedUsers',
      name: ROUTES.FLAGGED_USERS,
      component: FlaggedUsersViewResolver,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
    },
    {
      path: '/Utilities/DuplicateIncidents',
      name: ROUTES.DUPLICATE_INCIDENTS,
      component: DuplicateIncidents,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/utilities/incident-system-search',
      name: ROUTES.INCIDENT_SYSTEM_SEARCH,
      component: IncidentSystemSearch,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/PushNotifications',
      name: ROUTES.PUSH_NOTIFICATIONS,
      component: PushNotifications,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/ReleaseNotes',
      name: ROUTES.RELEASE_NOTES,
      component: ReleaseNotes,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
    },
    {
      path: '/Utilities/Export',
      name: ROUTES.UTILITIES_EXPORT,
      component: UtilitiesExport,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/ExportLogBilling',
      name: ROUTES.EXPORT_LOG_BILLING,
      component: ExportsLog,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
      props: {
        type: EXPORT_TYPES.BILLING,
        showAdvancedQuery: true,
        showExportTypeSelector: true,
      },
    },
    {
      path: '/Utilities/ExportLogDhs',
      name: ROUTES.EXPORT_LOG_DHS,
      component: ExportsLog,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
      props: { type: EXPORT_TYPES.DHS },
    },
    {
      path: '/Utilities/ExportLogUnfiltered',
      name: ROUTES.EXPORT_LOG_UNFILTERED,
      component: ExportsLog,
      meta: {
        rule: createAclRule([
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
      props: {
        type: EXPORT_TYPES.UNFILTERED,
        showTotalCounters: false,
      },
    },
    {
      path: '/Utilities/OdMapExports',
      name: ROUTES.OD_MAP_EXPORTS,
      component: OdMapExportsViewResolver,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
    },
    {
      path: '/Utilities/HDEExports',
      name: ROUTES.HDE_EXPORTS,
      component: HDEExport,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/utilities/et3-export',
      name: ROUTES.ET3_EXPORT_UTILITY,
      component: NemsisExportViewResolver,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
      props: {
        type: NEMSIS_EXPORT_TYPES.ET3,
      },
    },
    {
      path: '/utilities/nemsis-dhs-export',
      name: ROUTES.DHS_EXPORT_UTILITY,
      component: NemsisExportViewResolver,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
          ]),
        ]),
      },
      props: {
        type: NEMSIS_EXPORT_TYPES.DHS,
      },
    },
    {
      path: '/Utilities/FirstRunDevices',
      name: ROUTES.FIRST_RUN_DEVICES,
      component: FirstRunDevices,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/ErrorsUtility',
      name: ROUTES.ERRORS_UTILITY,
      component: ErrorsUtility,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities/Faxes',
      name: ROUTES.FAXES,
      component: Faxes,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
    {
      path: '/Utilities',
      name: ROUTES.UTILITIES,
      component: Utilities,
      meta: {
        rule: createAclRule([
          USER_ROLES.SUPER_ADMIN,
          getConditionObject(CLIENT_SITE_TYPES.DEFAULT, [
            USER_ROLES.CLIENT_ADMIN,
            USER_ROLES.CLIENT_ADMIN_IMPERSONATED,
            USER_ROLES.CLIENT_CAPTAIN_USER,
            USER_ROLES.CLIENT_FIREFIGHTER_USER,
          ]),
        ]),
      },
    },
    {
      path: '/ImpersonateAndRedirect',
      name: ROUTES.IMPERSONATE_AND_REDIRECT,
      component: ImpersonateAndRedirect,
      meta: {
        rule: ACL.SUPER_ADMIN,
      },
    },
  ],
});

export default createRouter;
