<template>
  <v-list :color="color">
    <template v-for="category in categories">
      <item-or-group
          v-if="isVisible(category)"
          :key="'navigation_item_' + category.id"
          :current-category="currentCategory"
          :category="category"
          :form-data="formData"
          :nesting-number="0"
          :error-messages="getErrorMessages(category)"
          @chooseCategory="chooseCategory"
      />
    </template>
  </v-list>
</template>
<script>
import _ from 'lodash';
import ItemOrGroup from '@/components/Neris/Navigation/ItemOrGroup';
import objectUtils from '@/components/Neris/Services/objectUtils';
import navigationMixin from './navigationMixin';

export default {
  components: {
    ItemOrGroup,
  },
  name: 'Navigation',
  mixins: [navigationMixin],
  props: {
    categories: {
      type: Object,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: undefined,
    },
    errorMessages: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isVisible(category) {
      if (this.countSubcategories(category)) {
        return !category.fieldProperties.hidden
            && this.hasVisibleCategories(category.subcategories);
      }
      return !category.fieldProperties.hidden;
    },

    hasVisibleCategories(categories) {
      let hasVisible = false;
      _.forEach(categories, category => {
        if (this.isVisible(category)) {
          hasVisible = true;
        }
      });
      return hasVisible;
    },

    chooseCategory(category) {
      if (category.type === 'category') {
        this.$emit('chooseCategory', category);
      }
    },

    getErrorMessages(category) {
      let errorMessages = {};
      if (category.field) {
        errorMessages = objectUtils.getByObjectPath(this.errorMessages, category.field);
      } else if (category.type === 'category') {
        _.forEach(category.structure, (item, field) => {
          errorMessages[field] = objectUtils.getByObjectPath(this.errorMessages, field);
        });
      } else if (category.type === 'group') {
        _.forEach(category.subcategories, (item, field) => {
          errorMessages[field] = objectUtils.getByObjectPath(this.errorMessages, field);
        });
      }
      return errorMessages || {};
    },
  },
};
</script>
