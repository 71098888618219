<template>
  <div :class="['item-padding-' + nestingNumber]">
    <item
        v-if="countSubcategories(category) === 0 && !category.fieldProperties.hidden"
        :title="category.title"
        :is-active="isActive"
        :show-count="category.fieldProperties.isArray"
        :required="category.fieldProperties.required || false"
        :count="category.fieldProperties.isArray ? getCountByObjectPath(category.field) : null"
        :icon="nestingNumber === 0 && category.icon ? category.icon : null"
        :nesting-number="nestingNumber"
        :has-errors="hasErrors"
        @click="chooseCategory(category)"
    />
    <group
        v-else-if="!category.fieldProperties.hidden"
        :title="category.title"
        :is-active="isActive"
        :items="category.subcategories"
        :current-category="currentCategory"
        :form-data="formData"
        :sub-group="nestingNumber > 0"
        :nesting-number="nestingNumber"
        :required="category.fieldProperties.required || false"
        :error-messages="errorMessages"
        :has-errors="hasErrors"
        @click="chooseCategory(category)"
        @chooseCategory="chooseCategory"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import Group from '@/components/Neris/Navigation/Group';
import Item from '@/components/Neris/Navigation/Item';
import navigationMixin from './navigationMixin';

export default {
  name: 'ItemOrGroup',
  mixins: [navigationMixin],
  components: { Item, Group },
  props: {
    category: {
      type: Object,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    hasErrors() {
      return this.hasErrorRecursive(this.errorMessages);
    },
    isActive() {
      return _.isEqual(this.currentCategory, this.category);
    },
  },
  methods: {
    chooseCategory(category) {
      this.$emit('chooseCategory', category);
    },

    hasErrorRecursive(errorMessages) {
      let hasErrors = false;
      _.forEach(errorMessages, (item) => {
        if (typeof item === 'string') {
          hasErrors = true;
        } else {
          const hasErrorNested = this.hasErrorRecursive(item);
          if (hasErrorNested) {
            hasErrors = true;
          }
        }
      });
      return hasErrors;
    },
  },
};
</script>
<style>
.neris-form {
  .item-padding-0 {
    .v-list-group__items .v-list-item{
      padding-left: 55px;
    }
  }
  .item-padding-1 {
    .v-list-group__items .v-list-item{
      padding-left: 80px;
    }
  }
}
</style>
