/* eslint func-names:0 */
export default {
  props: {
    fieldProperties: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
  },
};
