import _ from 'lodash';
import objectUtils from '@/components/Neris/Services/objectUtils';
import incidentTypeUtils from '@/components/Neris/Services/incidentTypeUtils';

/**
 * Service to dynamically adjust the structure of the form based on selected `incident_types`.
 *
 * This service:
 * - Evaluates the chosen `incident_types` and determines which sections should
 *   be visible or hidden.
 * - Toggles the accessibility of certain sections, enabling or disabling them as required.
 *
 * Key Features:
 * - Dynamically hides irrelevant sections based on the selected incident type.
 * - Enables or disables specific sections to guide the user in completing the form.
 * - Can be extended to include custom rules for additional `incident_types` in the future.
 *
 * Usage:
 * - This service should be invoked whenever `incident_types` changes.
 */
const incident = {
  process(structure, formData) {
    if (!structure) {
      return structure;
    }
    const incidentTypes = incidentTypeUtils.getTypes(formData);
    let structureObj = structure;
    const hasFireType = incidentTypeUtils.findIncidentType(incidentTypes, 'FIRE');
    const hasFireStructureType = incidentTypeUtils.findIncidentType(
      incidentTypes,
      'FIRE||STRUCTURE_FIRE',
    );
    const hasCookingFireSuppressionType = incidentTypeUtils.findIncidentType(
      incidentTypes,
      'FIRE||STRUCTURE_FIRE||CONFINED_COOKING_APPLIANCE_FIRE',
    );
    const hasHazsitType = incidentTypeUtils.findIncidentType(incidentTypes, 'HAZSIT');
    const hasMedicalType = incidentTypeUtils.findIncidentType(incidentTypes, 'MEDICAL');
    const map = {
      // FIRE
      'fire_detail.hidden': !hasFireType,
      'smoke_alarm.hidden': !hasFireType,
      'fire_alarm.hidden': !hasFireType,
      'other_alarm.hidden': !hasFireType,
      'fire_suppression.hidden': !hasFireType,
      // FIRE STRUCTURE
      'smoke_alarm.required': hasFireStructureType,
      'fire_alarm.required': hasFireStructureType,
      'other_alarm.required': hasFireStructureType,
      'fire_suppression.required': hasFireStructureType,
      // COOCKING FIRE SUPPRESSION
      'cooking_fire_suppression.hidden': !hasCookingFireSuppressionType,
      'cooking_fire_suppression.required': hasCookingFireSuppressionType,
      // HAZSIT
      'hazsit_detail.hidden': !hasHazsitType,
      // MEDICAL
      'medical_details.hidden': !hasMedicalType,
      // OTHER:
      'base.structure.location.required': false,
      'dispatch.structure.location.required': false,
      'exposures.structure.location.required': false,
    };

    _.forEach(map, (value, path) => {
      structureObj = objectUtils.setValueByObjectPath(structureObj, path, value, false);
    });

    return structureObj;
  },

};

export default incident;
