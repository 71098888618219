<template>
  <div>
    <v-toolbar
        class="neris-incidents-toolbar"
        flat
    >
      <date-range-picker-with-periods
          v-model="filtersData.incidentDateRange"
          class="mr-5"
          position="right"
          label="Incident Date"
      />
      <v-text-field
          v-model="filtersData.incidentNumber"
          hide-details
          class="mr-5"
          label="Incident Number"
          style="max-width: 230px"
      />
      <v-spacer />
      <v-btn
          class="mr-3"
          color="primary"
          @click="$emit('search', filtersData)"
      >
        Search
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>

import DateRangePickerWithPeriods from '@/components/DateRangePickerWithPeriods';

export default {
  name: 'Toolbar',
  components: { DateRangePickerWithPeriods },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filtersData: this.filters,
    };
  },
};
</script>

<style lang="scss">
.neris-incidents-toolbar {
  z-index: 10;
}
</style>
