import _ from 'lodash';

/* eslint-disable no-param-reassign */
const common = {
  mutate(formData, structure) {
    _.forEach(structure, (fieldProperties, fieldName) => {
      let fieldFormData = formData && formData[fieldName] ? formData[fieldName] : null;
      if (fieldProperties.type === 'object') {
        if (fieldProperties.isArray) {
          if (fieldFormData && fieldFormData.length) {
            fieldFormData = this.mutateArray(fieldFormData, fieldProperties.structure);
          }
        } else if (fieldFormData || fieldProperties.required) {
          fieldFormData = this.mutate(
            fieldFormData,
            fieldProperties.structure,
          );
        }
      } else if (fieldProperties.type === 'discriminator') {
        const discriminatorFormData = formData && formData[fieldName] ? formData[fieldName] : null;
        const type = discriminatorFormData ? discriminatorFormData.type : null;
        if (type) {
          const { mapping } = fieldProperties.discriminator;
          const objectName = mapping[type];
          const discriminatorStructure = fieldProperties.discriminator.objects[objectName];
          fieldFormData = this.mutate(
            discriminatorFormData,
            discriminatorStructure,
          );
        }
      } else if (fieldProperties.defaultValue && fieldProperties.hidden && !fieldFormData) {
        fieldFormData = fieldProperties.defaultValue;
      }

      if (fieldFormData !== null) {
        if (formData === null) {
          formData = {};
        }
        formData[fieldName] = fieldFormData;
      }
    });
    return formData;
  },

  mutateArray(formData, structure) {
    formData.forEach((item, index) => {
      formData[index] = this.mutate(item, structure);
    });
    return formData;
  },
};
export default common;
