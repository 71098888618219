<template>
  <div v-if="initialized">
    <div v-if="componentExists(fieldProperties.class)">
      <component
          v-bind:is="fieldProperties.class"
          v-model="formData"
          :structure="structure"
          :error-messages="errorMessages"
          :label="label"
      />
    </div>
    <div v-else>
      No Custom implementation for "{{ fieldProperties.class }}"
    </div>
  </div>
</template>
<script>

import _ from 'lodash';
import IncidentTypePayload from '@/components/Neris/CustomImplementations/IncidentTypePayload';
import GeoPoint from '@/components/Neris/CustomImplementations/GeoPoint';
import GeoMultipolygon from '@/components/Neris/CustomImplementations/GeoMultipolygon';
import formDataMixin from '@/components/Neris/mixins/formDataMixin';
import formsMixin from '@/components/Neris/mixins/formsMixin';

export default {
  name: 'CustomForm',

  mixins: [formDataMixin, formsMixin],

  components: {
    IncidentTypePayload,
    GeoPoint,
    GeoMultipolygon,
  },

  props: {
    fieldProperties: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      initialized: false,
    };
  },
  mounted() {
    const blankData = this.fieldProperties.isArray ? [] : {};
    this.formData = this.value ? _.cloneDeep(this.value) : blankData;
    this.initialized = true;
  },
  methods: {
    componentExists(componentName) {
      return Object.prototype.hasOwnProperty.call(
        this.$options.components,
        componentName,
      );
    },
  },
};
</script>
<style>

</style>
