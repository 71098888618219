const incidentTypeUtils = {
  findIncidentType(incidentTypes, specificType) {
    let hasType = false;
    incidentTypes.forEach(incidentType => {
      if (incidentType.startsWith(specificType)) {
        hasType = true;
      }
    });
    return hasType;
  },

  getTypes(formData) {
    if (!formData || !formData.incident_types || !formData.incident_types.length) {
      return [];
    }
    return formData.incident_types.map(item => item.type);
  },
};
export default incidentTypeUtils;
