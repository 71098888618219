/* eslint func-names:0 */

export default {
  props: {
    structure: {
      type: Object,
      required: true,
    },
    errorMessages: {
      type: [Array, Object],
      required: true,
    },
  },
};
